import { mapHelper } from '@/utils/common.js';

// 对应模块
const modular = [
  { label: '二维码扫码', value: 1 },
  { label: '活动签到用二维码', value: 2 },
  { label: '心愿墙', value: 3 },
  { label: '社区共建', value: 4 },
  { label: '公益捐赠', value: 5 },
  { label: '运动打卡', value: 7 },
  { label: '和伙人申请', value: 8 },
  { label: '积分商城兑换', value: 9 },
  { label: '注册', value: 11 },
  { label: '签到', value: 12 },
  { label: '点赞', value: 13 },
  { label: '评论', value: 14 },
  { label: '完善资料', value: 15 },
  { label: '与小和微信互动', value: 16 },
  { label: '超话活动', value: 17 },
  { label: '党建在线学习', value: 18 },
  { label: '邻里公约', value: 19 },
  { label: '访问积分商城页面', value: 20 },
  { label: '访问小和超话页面', value: 21 },
  { label: '访问小和广播站页面', value: 22 },
  { label: '访问活动广场页面', value: 23 },
  { label: '完成组合任务', value: 24 },
  { label: '扫描场地预约二维码', value: 25 },
  { label: '商圈扫码', value: 26 },
  { label: '时间银行签到', value: 27 },
  { label: '微信步数', value: 28 },
  { label: '活动达人', value: 29 },
  { label: '浏览法制知识库', value: 41 },
  { label: '抽奖发放积分', value: 42 },
  { label: '老年大学上课签到', value: 43 },
  { label: '积分转赠', value: 99 },
  { label: '幸福学堂', value: 51 },
  { label: '时间银行', value: 52 },
  { label: '乡贤活动', value: 53 },
  { label: '青少年活动', value: 54 },
  { label: '金钟法制', value: 101 },
  { label: '先锋任务', value: 102 },
  { label: '青少年关爱', value: 103 },
  { label: '南溪阿姐', value: 104 },
  { label: '申请志愿者', value: 105 },
  { label: '三方应用', value: 1000 },
];
const { map: modularMap, setOps: setModularOps } = mapHelper.setMap(modular);

export { modular, modularMap, setModularOps };
