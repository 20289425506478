<template>
  <div class="integral-list">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
      :isManySelect="false"
      :isIndex="false"
      :isOperateColumn="false"
    >
      <template #searchSlot>
        <v-input label="用户名称" v-model="searchParam.userName" clearable placeholder="请输入用户名称" />
        <v-date-picker
          label="时间"
          v-model="optTime"
          type="datetimerange"
          formatValue="yyyy-MM-dd HH:mm:ss"
          format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']"
          startPlaceholder="开始时间"
          endPlaceholder="结束时间"
        />
      </template>
    </v-list>
  </div>
</template>

<script>
import { getIntegralListtUrl } from './api.js';
import { modularMap } from './map.js';

export default {
  name: 'pubCapInteCenIntegralList',
  data() {
    return {
      searchParam: {
        tenantId: '',
        userName: '',
        startDate: '',
        endDate: ''
      },
      tableUrl: getIntegralListtUrl,
      headers: [
        {
          prop: 'userName',
          label: '用户名称',
          align: 'center'
        },
        {
          prop: 'recordType',
          label: '对应模块',
          align: 'center',
          formatter: (row, prop) => {
            return row[prop] > 1000 ? modularMap[1000] : modularMap[row[prop]] || row[prop];
          }
        },
        {
          prop: 'insertTime',
          label: '时间',
          align: 'center'
        },
        {
          prop: 'tokenPoint',
          label: '积分变化',
          align: 'center',
          formatter: (row, prop) => {
            const fh = row.operationType === 0 ? '-' : '+';
            return fh + row[prop];
          }
        }
      ]
    };
  },
  computed: {
    optTime: {
      get() {
        return !this.searchParam.startDate && !this.searchParam.endDate ? [] : [this.searchParam.startDate, this.searchParam.endDate];
      },
      set(val) {
        [this.searchParam.startDate, this.searchParam.endDate] = val || ['', ''];
      }
    }
  },
  created() {
    this.searchParam.tenantId = this.$store.state.app.userInfo.tenantId;
  },
  mounted() {},
  methods: {}
};
</script>

<style lang="less" scoped>
.integral-list {
  box-sizing: border-box;
  height: 100%;
}
</style>
